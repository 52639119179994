/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

// Images
import bgImage from "assets/images/bg_join.png";
import bgImageMob from "assets/images/bg_join_mob.png";

import { useEffect, useState } from "react";
import breakpoints from "../../../assets/theme/base/breakpoints";

function getBgImage() {
  if (window.innerWidth <= breakpoints.values.xxs) {
    return bgImageMob;
  }
  return bgImage;
}

function renderBgVideo() {
  if (window.innerWidth >= breakpoints.values.xxs) {
    return (
      <video autoPlay playsInline muted style={{ position: "absolute", width: "100%", zIndex: 2 }}>
        <source
          src="https://thespiritbroker-assets.s3.ca-central-1.amazonaws.com/video/join_video_var.mov"
          type="video/mp4"
        />
      </video>
    );
  }

  // if (window.innerWidth <= breakpoints.values.xxs) {
  //   return (
  //     <video
  //       autoPlay
  //       muted
  //       style={{ position: "absolute", height: "100%", left: "-100%", zIndex: 1 }}
  //     >
  //       <source
  //         src="https://thespiritbroker-assets.s3.ca-central-1.amazonaws.com/video/join_video_mob.mov"
  //         type="video/mp4"
  //       />
  //     </video>
  //   );
  // }
  return <div />;
}

function Download() {
  const [bgUrl, setBgUrl] = useState("");
  const [bgVid, setBgVid] = useState("");
  const [boxHeight, setBoxHeight] = useState(600);
  const [buttonHeight, setButtonHeight] = useState(400);
  useEffect(() => {
    // A function that sets the display state for the TRWNavbarMobile.
    function displayMobileBg() {
      setBgUrl(getBgImage());
      setBgVid(renderBgVideo());

      if (window.innerWidth <= breakpoints.values.xl) {
        setBoxHeight(400);
        setButtonHeight(300);
      }

      if (window.innerWidth <= breakpoints.values.lg) {
        setBoxHeight(300);
        setButtonHeight(200);
      }
      if (window.innerWidth <= breakpoints.values.md) {
        setBoxHeight(200);
        setButtonHeight(100);
      }
      if (window.innerWidth <= breakpoints.values.sm) {
        setBoxHeight(300);
        setButtonHeight(50);
      }
      if (window.innerWidth <= breakpoints.values.xxs) {
        setBoxHeight(300);
        setButtonHeight(300);
      }
    }

    /**
     The event listener that's calling the displayMobileBg function when
     resizing the window.
     */
    window.addEventListener("resize", displayMobileBg);

    // Call the displayMobileBg function to set the state with the initial value.
    displayMobileBg();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileBg);
  }, []);
  return (
    <MKBox component="section" py={{ xs: 0, sm: 12 }}>
      <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgUrl}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
        />
        {bgVid}
        <Container sx={{ position: "relative", zIndex: 2, py: 12, minHeight: boxHeight }}>
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent="space-between"
            mx="auto"
            textAlign="center"
            sx={{ minHeight: buttonHeight }}
          />
          <Grid
            container
            item
            xs={12}
            md={7}
            justifyContent="space-between"
            mx="auto"
            textAlign="center"
          >
            <MKButton
              variant="gradient"
              color="blues"
              size="large"
              component="a"
              href="https://netflix.com/"
              sx={{ mb: 2 }}
            >
              Go to Netflix
            </MKButton>
            <MKButton
              variant="gradient"
              color="error"
              size="large"
              component="a"
              href="https://therealworld.ai/a/l9nl8q7gmt"
              sx={{ mb: 2 }}
            >
              Join Us
            </MKButton>
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default Download;
