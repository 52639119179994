import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import { useState } from "react";
import MKButton from "components/MKButton";
import MKAlert from "components/MKAlert";

function callSub(email, setSubOk, setSub) {
  fetch("https://uystbn87ul.execute-api.ca-central-1.amazonaws.com/prod/subscribe", {
    method: "PUT",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      email: email.target.value,
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        setSubOk(true);
      } else {
        setSubOk(false);
      }
    })
    .catch(() => {
      setSubOk(false);
    })
    .finally(() => setSub(true));
}

function drawAlert(sub, subOk) {
  if (!sub) {
    return <div />;
  }

  if (subOk) {
    return (
      <MKAlert color="info">
        Subscribed successfully. Feel free to reach out using the chat in the bottom right corner as
        well.
      </MKAlert>
    );
  }
  return (
    <MKAlert color="error">
      Subscribe failed, do not give up! Please reach out using the chat in the bottom corner.
    </MKAlert>
  );
}

function Subscribe() {
  const [subOk, setSubOk] = useState(true);
  const [email, setEmail] = useState("");
  const [sub, setSub] = useState(false);

  return (
    <MKBox component="section" py={1} my={1}>
      <Container>
        <Grid
          item
          xs={12}
          lg={10}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            Subscribe for weekly motivation
          </MKTypography>
          <MKTypography variant="body1" color="text">
            We will constantly remind you why you are a loser, and how you can stop being one.
          </MKTypography>
        </Grid>
        <Grid
          item
          xs={12}
          lg={10}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKInput
            label="Your email here..."
            onChange={setEmail}
            sx={{
              width: "50%",
              maxWidth: "300px",
              minWidth: "200px",
              mx: "auto",
              px: 0.75,
              margin: "0.3rem",
            }}
          />
          <MKButton
            component="button"
            onClick={() => {
              callSub(email, setSubOk, setSub);
              setTimeout(() => {
                setSub(false);
              }, 5000);
            }}
            rel="noreferrer"
            variant="gradient"
            color="info"
            size="medium"
            sx={{
              mx: "auto",
              px: 0.75,
              margin: "0.3rem",
            }}
          >
            Subscribe
          </MKButton>
        </Grid>
        {sub && drawAlert(sub, subOk)}
      </Container>
    </MKBox>
  );
}
export default Subscribe;
