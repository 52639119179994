/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// Images
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

import bgCrypto from "assets/images/bg_crypto.jpg";
import bgStocks from "assets/images/bg_stocks.jpg";

import MKTypography from "../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={1} my={1}>
      <Container>
        <Grid
          item
          xs={12}
          lg={10}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            1. Multiply Your Income
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Once we have you reach the 6-figures threshold, we begin to teach you investment
            strategies to multiply your profits.
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 0 } }}>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront
                  image={bgCrypto}
                  icon=<CurrencyBitcoinIcon />
                  title={<>Crypto</>}
                />
                <RotatingCardBack
                  image={bgBack}
                  title="Crypto"
                  description={
                    <>
                      Profiting from the world’s highest-performing assets. <br />
                      You’ll be able to choose between cryptocurrencies, NFTs, and De-Fi. <br />
                      We’ll focus on attacking crypto markets from 3 angles: <br />
                      Long-term, medium-term, and short-term investments.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/crypto?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront image={bgStocks} icon=<TrendingUpIcon /> title={<>Stocks</>} />
                <RotatingCardBack
                  image={bgBack}
                  title="Stocks"
                  description={
                    <>
                      Multiplying your capital through the stock market. <br />
                      We’ll train you to use technical analysis to find situations where there’s a
                      higher upside than the downside in the markets. <br />
                      You’ll profit from such opportunities by trading stocks and options.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/stocks?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
