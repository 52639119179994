/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import CreateIcon from "@mui/icons-material/Create";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import HandshakeIcon from "@mui/icons-material/Handshake";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import WarehouseIcon from "@mui/icons-material/Warehouse";

// Back card images
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

// Front card images
import bgCopywrite from "assets/images/bg_copywrite.jpg";
import bgFreelance from "assets/images/bg_freelance.jpg";
import bgEcommerce from "assets/images/bg_ecommerce.jpg";
import bgFba from "assets/images/bg_fba.jpg";

import MKBadge from "../../../components/MKBadge";
import MKTypography from "../../../components/MKTypography";

function StepOne() {
  return (
    <MKBox component="section" py={1} my={1}>
      <Container>
        <Grid
          item
          xs={12}
          lg={10}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            1. Choose a Skill
          </MKTypography>
          <MKTypography variant="body1" color="text">
            Upon joining, we’ll start you with one of 5 business models that are:
          </MKTypography>
          <MKBadge
            variant="gradient"
            color="info"
            badgeContent="Completely online"
            size="lg"
            circular
            border
            container
          />
          <MKBadge
            variant="gradient"
            color="info"
            badgeContent="Fast to generate profits"
            size="lg"
            circular
            border
            container
          />
          <MKBadge
            variant="gradient"
            color="info"
            badgeContent="Scalable to a 6-figure income"
            size="lg"
            circular
            border
            container
          />
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 0 } }}>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront
                  image={bgCopywrite}
                  icon=<CreateIcon />
                  title={<>Copywriting</>}
                />
                <RotatingCardBack
                  image={bgBack}
                  title="Copywriting"
                  description={
                    <>
                      Selling with words. <br />
                      When you read the words on websites, emails, or ads... those words were the
                      work of a copywriter. <br />
                      We’ll teach you how to master the art of copywriting and secure high-paying
                      clients.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/copywriting?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront
                  image={bgFreelance}
                  icon=<HandshakeIcon />
                  title={<>Freelancing</>}
                />
                <RotatingCardBack
                  image={bgBack}
                  title="Freelancing"
                  description={
                    <>
                      Selling your skills. <br />
                      You’ll learn how to get paid a premium price to complete simple tasks, like
                      designing logos, translating eBooks, editing videos, managing social accounts,
                      creating websites, etc. <br />
                      And then we’ll teach you the most effective methods to build a list of loyal
                      clients.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/freelancing?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
          </Grid>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 0 } }}>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront
                  image={bgEcommerce}
                  icon=<ShoppingCartIcon />
                  title={<>E-Commerce</>}
                />
                <RotatingCardBack
                  image={bgBack}
                  title="E-Commerce"
                  description={
                    <>
                      Selling products online. <br />
                      Industry experts will train you to find winning products, create the perfect
                      store, and generate traffic on your sites. <br />
                      Whether you’re interested in dropshipping or private labeling, we streamlined
                      the whole process from having zero experience to owning a profitable online
                      store.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/e-commerce?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront image={bgFba} icon=<WarehouseIcon /> title={<>Amazon FBA</>} />
                <RotatingCardBack
                  image={bgBack}
                  title="Amazon FBA"
                  description={
                    <>
                      What does FBA stand for? Fulfillment by Amazon. <br />
                      This means you sell products online, but Amazon handles the rest, from
                      warehousing products, fulfilling their orders, shipping them out, etc. <br />
                      All you need to do is get people to click “buy”. <br />
                      We’ll teach you how to find the most profitable products and dominate your
                      competition.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/amazon-fba?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default StepOne;
