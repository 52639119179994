/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

import PsychologyIcon from "@mui/icons-material/Psychology";
import CorporateFareIcon from "@mui/icons-material/CorporateFare";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";

// Images
import bgBack from "assets/images/rotating-card-bg-back.jpeg";

import bgBusiness from "assets/images/bg_business.jpg";
import bgMindset from "assets/images/bg_mindset.jpg";

import MKTypography from "../../../components/MKTypography";

function Information() {
  return (
    <MKBox component="section" py={1} my={1}>
      <Container>
        <Grid
          item
          xs={12}
          lg={10}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" fontWeight="bold">
            3. Scale your Business
          </MKTypography>
          <MKTypography variant="body1" color="text">
            The final step is mentoring you on how to fine-tune your operations and accelerate your
            business growth.
          </MKTypography>
        </Grid>
      </Container>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid container spacing={3} sx={{ mt: { xs: 0, md: 0 } }}>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront
                  image={bgBusiness}
                  icon=<CorporateFareIcon />
                  title={<>Business & Finance</>}
                />
                <RotatingCardBack
                  image={bgBack}
                  title="Business & Finance"
                  description={
                    <>
                      We’ll train you to be in full control of your operations, hiring, and
                      finances. <br />
                      Then we’ll begin teaching you the fine details of scaling your business to 7
                      figures.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/business-finance?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
            <Grid item xs={12} md={6}>
              <RotatingCard>
                <RotatingCardFront image={bgMindset} icon=<PsychologyIcon /> title={<>Mindset</>} />
                <RotatingCardBack
                  image={bgBack}
                  title="Mindset"
                  description={
                    <>
                      You must think like a millionaire to grow into one.
                      <br />
                      Our professors will reprogram your mentality with the powerful mind hacks
                      behind their success.
                    </>
                  }
                  action={{
                    type: "external",
                    route: "https://www.therealworld.ai/skills/mindset?a=l9nl8q7gmt",
                    label: "See student results",
                  }}
                />
              </RotatingCard>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
