/*
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples

// TheRealWorld page sections
import Counters from "pages/TheRealWorld/sections/Counters";
import StepOne from "pages/TheRealWorld/sections/StepOne";
import Download from "pages/TheRealWorld/sections/Download";

// TheRealWorld page components

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/bg_trw.png";
import bgImageMob from "assets/images/bg_trw_mob.png";

// Material Kit 2 React base styles
import breakpoints from "assets/theme/base/breakpoints";
import { useEffect, useState } from "react";
import StepTwo from "pages/TheRealWorld/sections/StepTwo";
import StepThree from "pages/TheRealWorld/sections/StepThree";
import TRWNavbar from "examples/Navbars/TRWNavBar";
import WhatIsTRW from "./sections/WhatIsTRW";
import MKTypography from "../../components/MKTypography";
import TRWFooter from "../../examples/Footers/TRWFooter";
import Subscribe from "./components/Subscribe";

function renderBgVideo() {
  if (window.innerWidth >= breakpoints.values.lg) {
    return (
      <video autoPlay playsInline muted style={{ position: "absolute", width: "100%", zIndex: 0 }}>
        <source
          src="https://thespiritbroker-assets.s3.ca-central-1.amazonaws.com/video/header_intro.mov"
          type="video/mp4"
        />
      </video>
    );
  }

  return <div />;
}

function getBgImage() {
  if (window.innerWidth <= breakpoints.values.sm) {
    return bgImageMob;
  }
  return bgImage;
}

function TheRealWorld() {
  const [bgUrl, setBgUrl] = useState("");
  const [videoCard, setVideoCard] = useState(<div />);
  useEffect(() => {
    // A function that sets the display state for the TRWNavbarMobile.
    function displayMobileBg() {
      setBgUrl(getBgImage());
      setVideoCard(renderBgVideo());
    }

    /**
         The event listener that's calling the displayMobileBg function when
         resizing the window.
         */
    window.addEventListener("resize", displayMobileBg);

    // Call the displayMobileBg function to set the state with the initial value.
    displayMobileBg();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", displayMobileBg);
  }, []);
  return (
    <>
      <TRWNavbar
        routes={routes}
        action={{
          type: "external",
          route: "https://therealworld.ai/?a=l9nl8q7gmt",
          label: "join today",
          color: "info",
        }}
        sticky
      />
      <MKBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: `url(${bgUrl})`,
          backgroundSize: "cover",
          backgroundPosition: "top",
          display: "grid",
          placeItems: "center",
        }}
      >
        {videoCard}
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <Counters />
        <WhatIsTRW />
        <MKBox component="section" py={1} my={1}>
          <Container>
            <Grid
              item
              xs={12}
              lg={10}
              flexDirection="column"
              alignItems="center"
              sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
            >
              <MKTypography variant="h1">What do we teach?</MKTypography>
              <MKTypography variant="body1" color="text">
                Well train you to build a large income, then you’ll be taught to multiply it and
                manage it.
              </MKTypography>
            </Grid>
          </Container>
        </MKBox>
        <StepOne />
        <StepTwo />
        <StepThree />
        <Download />
        <Subscribe />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <TRWFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default TheRealWorld;
